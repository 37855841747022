<script>
  import { inertia, Link } from '@inertiajs/svelte'

  export let users;
</script>

<div class="overflow-x-auto">
  <h3 class="text-3xl font-bold">The LOKBROS Dash</h3>
  <p>Featuring all the Assimilation peeps.</p>

  <table class="mt-5 table table-zebra">
    <!-- head -->
    <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Email</th>
        <th>Subscription Status</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {#each users as user}
        <tr>
          <th>{user.id}</th>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.subscription_status}</td>
          <td><button use:inertia="{{ href: `/users/${user.id}/edit` }}" class="btn btn-accent">Edit</button></td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

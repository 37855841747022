<section class="h-[70vh] hero">
  <video
    autoplay
    loop
    muted
    playsinline
    class="absolute left-0 -z-10 h-[70vh] w-full object-cover"
  >
    <source
      src="https://nyc3.digitaloceanspaces.com/lokbros-assets/assimilation-rails/assimillationHomePage.mp4"
      type="video/mp4"
    />
    Your browser does not support the video tag.
  </video>
  <div
    class="bg-gradient-to-b from-transparent to-base-100 absolute left-0 h-[70vh] w-full object-cover"
  ></div>
  <div
    class="bg-base-100 absolute left-0 h-[70vh] w-full object-cover opacity-30"
  ></div>
  <div
    class="relative z-10 h-full bg-opacity-50 md:ml-12 md:items-end md:justify-start md:p-10 max-w-md"
  >
    <div
      class="flex flex-col justify-center pt-[15vh] text-center align-bottom"
    >
      <h1 class="text-5xl font-bold">Assimilation</h1>
      <p class="py-6">
        A mesmerizing pixel movement and expansion pattern that grows to fill
        every screen.
      </p>
      <div class="flex gap-1 justify-center">
        <a href="/play" class="btn btn-secondary">Play with it live</a>
      </div>
    </div>
  </div>
</section>

<section class="py-16 mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
  <h1 class="text-3xl font-bold">What is It?</h1>
  <p class="py-6">
    Assimilation is a cellular automata graphical simulation (a mouthful!). Each
    pixel's fate is written in code, but they interact together in a dance
    leaving behind intricate, unique patterns. It works great at events with and
    without live video integration. As art, it pulses with meaning.
  </p>
  <h1 class="mt-5 text-3xl font-bold">At Events</h1>
  <p class="py-6">
    Assimilation is great at visual events because it grows dynamically to any
    screen size. It looks great on its own, or with live video (if you're a
    performer) or it can be made interactive! Colors, matching music or videos -
    <a
      href="https://app.youform.com/forms/my2dpvlx"
      class="underline hover:no-underline focus:outline-none"
      >Let's talk
    </a> if you'd like Assimilation at your event!
  </p>
  <h1 class="mt-5 text-3xl font-bold">Can I access it online?</h1>
  <p class="py-6">
    Yes! You can play with a black and white version of Assimilation <a
      href="/play"
      class="underline">here</a
    >. There's a screenshot function that lets you download the pure image
    (without ui controls), so you can use the art you made however you like! You
    can get colors for a small fee, or you can contact us at hello@lokbros.com
    if you're looking for something more specific.
  </p>
  <h1 class="mt-5 text-3xl font-bold">Tell me more</h1>
  <p>
    Thank you for taking interest :). We'll put more about how Assimilation was
    created, how it works, and the meaning we and others take from it
    <a class="underline hover:no-underline" href="/prophecy">here</a>.
  </p>
</section>

<footer class="footer footer-center bg-base-300 text-base-content p-4">
  <aside>
    <p>
      Copyright © {new Date().getFullYear()} - All right reserved by
      <a class="underline" href="https://lokbros.com">LokBros Studio</a>.
    </p>
  </aside>
</footer>

<script>
import { useForm } from '@inertiajs/svelte'

export let token

let form = useForm({
  password: null,
  password_confirmation: null,
})

function submit() {
  $form.put(`/password/${token}`)
}
</script>

<section class="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
  <h1 class="text-3xl mt-8 text-center">Update your password</h1>

  <form on:submit|preventDefault={submit} class="flex flex-col items-center gap-3">
    <input
      class="input input-bordered w-full max-w-xs"
      type="password"
      bind:value={$form.password}
      required
      autocomplete="new-password"
      placeholder="Enter new password"
      maxlength="72"
    />
    <input
      class="input input-bordered w-full max-w-xs"
      type="password"
      bind:value={$form.password_confirmation}
      required
      autocomplete="new-password"
      placeholder="Repeat new password"
      maxlength="72"
    />
    <button class="btn btn-accent" type="submit" disabled={$form.processing}>
      Save
    </button>
  </form>
</section>

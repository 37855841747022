<script lang="ts">
  import { onMount } from "svelte";
  import { main } from "/assets/js/main.js";
  import { router } from "@inertiajs/svelte";

  export let paidUser = false;
  export let loggedInUser = false;
  export let userName;
  export let vertexShader;
  export let fragmentShader;
  export let feedbackShader;

  const _drawInfo = (gl, shaderProgram) => ({
    program: shaderProgram,
    a: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "a_position"),
    },
    u: {
      time: gl.getUniformLocation(shaderProgram, "time"),
      resolution: gl.getUniformLocation(shaderProgram, "resolution"),
      colorMode: gl.getUniformLocation(shaderProgram, "colorMode"),
    },
  });

  const _feedbackInfo = (gl, shaderProgram) => ({
    program: shaderProgram,
    a: {
      vertexPosition: gl.getAttribLocation(shaderProgram, "a_position"),
    },
    u: {
      time: gl.getUniformLocation(shaderProgram, "time"),
      resolution: gl.getUniformLocation(shaderProgram, "resolution"),
      feedbackTex: gl.getUniformLocation(shaderProgram, "feedbackTexture"),
      up: gl.getUniformLocation(shaderProgram, "up"),
      down: gl.getUniformLocation(shaderProgram, "down"),
      left: gl.getUniformLocation(shaderProgram, "left"),
      right: gl.getUniformLocation(shaderProgram, "right"),
      diagonals: gl.getUniformLocation(shaderProgram, "diagonals"),
      growthGen: gl.getUniformLocation(shaderProgram, "growthGen"),
    },
  });

  let guiVars = {
    speed: 5.0,
    growthGen: 1.0,
    pulse: true,
    pulseTime: 30,
    diagonals: false,
    hidden: false,
    up: true,
    down: true,
    left: true,
    right: true,
    pause: false,
    reset: false,
    colorMode: 1.0,
    screenShotNow: false,

    Reset: () => (guiVars.reset = true),
  };

  const _globals = {
    backTex: null,
    frontTex: null,
    framebuffer: null,
    interval: 900000, // 15 minutes
    pixelDensity: 2,
    guiVars,
  };

  const _init = (gl, fb, draw) => {
    //feedback
    gl.useProgram(fb.program);
    gl.uniform2f(
      fb.u.resolution,
      gl.drawingBufferWidth,
      gl.drawingBufferHeight,
    );
    gl.enableVertexAttribArray(fb.a.position);
    gl.vertexAttribPointer(fb.position, 2, gl.FLOAT, false, 0, 0);
    //draw
    gl.useProgram(draw.program);
    gl.uniform2f(
      draw.u.resolution,
      gl.drawingBufferWidth * _globals.pixelDensity,
      gl.drawingBufferHeight * _globals.pixelDensity,
    );
    gl.enableVertexAttribArray(draw.a.position);
    gl.vertexAttribPointer(draw.a.position, 2, gl.FLOAT, false, 0, 0);
  };

  function intervalMethod() {
    document.getElementById("my_modal_1").showModal();
  }

  function onChangeColor() {
    if (paidUser) {
      guiVars.colorMode = (guiVars.colorMode + 1) % 7;
    } else {
      document.getElementById("my_modal_2").showModal();
    }
  }

  function onUserInfo() {
    document.getElementById("my_modal_3").showModal();
  }

  function signUserOut() {
    router.delete("/session");
  }

  const saveBlob = (function () {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    return function saveData(blob, fileName) {
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
    };
  })();

  function screenshot() {
    const canvas = document.getElementById("gl");
    canvas.toBlob((blob) => {
      saveBlob(blob, `screencapture-${canvas.width}x${canvas.height}.png`);
    });
  }

  export const _tick = (gl, fbInfo, drawInfo, time) => {
    if (time % _globals.guiVars.speed !== 0) {
      return;
    }
    gl.useProgram(fbInfo.program);

    gl.uniform1f(fbInfo.u.time, time);
    gl.uniform1f(fbInfo.u.up, _globals.guiVars.up);
    gl.uniform1f(fbInfo.u.down, _globals.guiVars.down);
    gl.uniform1f(fbInfo.u.left, _globals.guiVars.left);
    gl.uniform1f(fbInfo.u.right, _globals.guiVars.right);
    gl.uniform1f(fbInfo.u.diagonals, _globals.guiVars.diagonals);
    if (_globals.guiVars.reset === true) {
      gl.uniform1f(fbInfo.u.growthGen, 5.0);
      _globals.guiVars.reset = false;
    } else if (_globals.guiVars.pause === true) {
      gl.uniform1f(fbInfo.u.growthGen, 4.5);
    } else if (_globals.guiVars.pulse === true)
      gl.uniform1f(
        fbInfo.u.growthGen,
        (time / (_globals.guiVars.pulseTime * _globals.guiVars.speed)) % 4,
      );
    else {
      gl.uniform1f(fbInfo.u.growthGen, _globals.guiVars.growthGen);
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, _globals.framebuffer);
    gl.framebufferTexture2D(
      gl.FRAMEBUFFER,
      gl.COLOR_ATTACHMENT0,
      gl.TEXTURE_2D,
      _globals.frontTex,
      0,
    );
    //gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

    gl.activeTexture(gl.TEXTURE1);
    gl.bindTexture(gl.TEXTURE_2D, _globals.backTex);
    gl.uniform1i(fbInfo.u.feedbackTex, 1);
    gl.drawArrays(gl.TRIANGLES, 0, 6);

    // swap our front and back textures
    let tmp = _globals.frontTex;
    _globals.frontTex = _globals.backTex;
    _globals.backTex = tmp;

    // use the default framebuffer object by passing null
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.activeTexture(gl.TEXTURE0);
    gl.bindTexture(gl.TEXTURE_2D, _globals.frontTex);
    gl.useProgram(drawInfo.program);

    gl.uniform1f(drawInfo.u.colorMode, guiVars.colorMode);
    gl.uniform1f(drawInfo.u.time, time);
    gl.drawArrays(gl.TRIANGLES, 0, 6);
    if (_globals.guiVars.screenShotNow) {
      screenshot();
      _globals.guiVars.screenShotNow = false;
    }
  };

  onMount(() => {
    // TODO: activate this when not in dev mode
    window.addEventListener("resize", () => {
      window.location.reload();
    });

    window.addEventListener("keydown", (e) => {
      switch (e.key) {
        default:
          break;
        case "t":
          guiVars.growthGen -= 0.01;
          break;
        case "`":
          guiVars.growthGen += 0.01;
          break;
        case "1":
          guiVars.speed = Math.max(1, guiVars.speed - 1);
          break;
        case "2":
          guiVars.speed = Math.min(20, guiVars.speed + 1);
          break;
        case "r":
          guiVars.Reset();
          break;
        case "h":
          guiVars.hidden = !guiVars.hidden;
          break;
        case "p":
          guiVars.pulse = !guiVars.pulse;
          break;
        case "q":
          guiVars.pulse = !guiVars.pulse;
          break;
        case "c":
          guiVars.pulseTime = Math.max(1, guiVars.pulseTime - 1);
          break;
        case "v":
          guiVars.pulseTime += 1;
          break;
        case "o":
          guiVars.diagonals = !guiVars.diagonals;
          break;
        case "w":
          guiVars.diagonals = !guiVars.diagonals;
          break;
        case " ":
          guiVars.pause = !guiVars.pause;
          break;
        case "ArrowUp":
          guiVars.up = !guiVars.up;
          break;
        case "ArrowDown":
          guiVars.down = !guiVars.down;
          break;
        case "ArrowLeft":
          guiVars.left = !guiVars.left;
          break;
        case "ArrowRight":
          guiVars.right = !guiVars.right;
          break;
        case "Enter":
          if (paidUser) onChangeColor();
          break;
      }
    });
    setInterval(intervalMethod, _globals.interval);
    intervalMethod();
    main(
      vertexShader,
      feedbackShader,
      fragmentShader,
      _globals,
      _drawInfo,
      _feedbackInfo,
      _init,
      _tick,
    );
  });
</script>

<!-- Open the modal using ID.showModal() method -->
<dialog id="my_modal_1" class="modal">
  <div class="modal-box">
    <h3 class="text-lg font-bold">Try Assimilation</h3>
    <p class="py-4">Welcome to this small portal into Assimilation.</p>
    <p class="hidden md:block py-4">
      Move the cursor around the screen to seed assimilation, and watch it grow
      to any screen size. Click the icons below to change its growth.
    </p>
    <p class="md:hidden py-4">
      Draw on the screen to seed assimilation, and watch it grow to any screen
      size. Touch the icons below to change its growth.
    </p>
    <p class="py-4">
      Click the camera icon to download your Assimilation Still.
    </p>
    <p class="py-4">
      This message reappears every 15 minutes.
      <a
        href="https://app.youform.com/forms/my2dpvlx"
        class="underline hover:no-underline focus:outline-none"
        >Let's talk
      </a>
      if you want to use Assimilation for an event or live video.
    </p>
    <p class="py-4">
      <i>!! For those sensitive to flashing, proceed with caution. !!</i>
    </p>
    <div class="modal-action">
      <form method="dialog">
        <!-- if there is a button in form, it will close the modal -->
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>

<dialog id="my_modal_2" class="modal">
  <div class="modal-box">
    <h3 class="text-lg font-bold">Unlock Colors (if you want)</h3>
    <p class="py-4">
      Get Assimilation in a bunch of awesome color palletes and support LokBros
      Studio with $9.99!
    </p>
    <p class="py-4">
      If you already have colors, <a
        href="/session/new"
        class="underline hover:no-underline">sign in.</a
      >
    </p>
    <p class="py-4">
      If you want to get some color, it's a two-step process - sign up, then
      enter payment information. We appreciate the interest!
    </p>

    <div class="modal-action">
      <form method="dialog">
        <a href="/checkout" class="btn btn-primary">Get Colors</a>
        <button class="btn">Close</button>
      </form>
    </div>
  </div>
</dialog>

<dialog id="my_modal_3" class="modal">
  <div class="modal-box">
    {#if loggedInUser}
      <h3 class="text-lg font-bold text-center">Hello, {userName}</h3>
      <p class="text-center">You are logged in.</p>
      <div class="modal-action justify-center">
        <form method="dialog">
          <!-- if there is a button in form, it will close the modal -->
          <button class="btn btn-accent" on:click={signUserOut}>Sign Out</button
          >
          <button class="btn">Close</button>
        </form>
      </div>
    {:else}
      <h3 class="text-lg font-bold text-center">Anonymous</h3>
      <p class="text-center">You are not logged in.</p>
      <div class="modal-action justify-center">
        <form method="dialog">
          <!-- if there is a button in form, it will close the modal -->
          <a class="btn btn-accent" href="/session/new">Sign In</a>
          <button class="btn">Close</button>
        </form>
      </div>
    {/if}
  </div>
</dialog>

<div class="absolute top-0 right-0 p-2">
  <button
    class={`btn btn-circle p-1 ${guiVars.hidden ? "opacity-40" : ""}`}
    on:click={() => (guiVars.hidden = !guiVars.hidden)}
    ><svg
      viewBox="0 0 24 24"
      width="40"
      height="40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      ><g id="SVGRepo_bgCarrier" stroke-width="3"></g><g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g><g id="SVGRepo_iconCarrier">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.30147 15.5771C4.77832 14.2684 3.6904 12.7726 3.18002 12C3.6904 11.2274 4.77832 9.73158 6.30147 8.42294C7.87402 7.07185 9.81574 6 12 6C14.1843 6 16.1261 7.07185 17.6986 8.42294C19.2218 9.73158 20.3097 11.2274 20.8201 12C20.3097 12.7726 19.2218 14.2684 17.6986 15.5771C16.1261 16.9282 14.1843 18 12 18C9.81574 18 7.87402 16.9282 6.30147 15.5771ZM12 4C9.14754 4 6.75717 5.39462 4.99812 6.90595C3.23268 8.42276 2.00757 10.1376 1.46387 10.9698C1.05306 11.5985 1.05306 12.4015 1.46387 13.0302C2.00757 13.8624 3.23268 15.5772 4.99812 17.0941C6.75717 18.6054 9.14754 20 12 20C14.8525 20 17.2429 18.6054 19.002 17.0941C20.7674 15.5772 21.9925 13.8624 22.5362 13.0302C22.947 12.4015 22.947 11.5985 22.5362 10.9698C21.9925 10.1376 20.7674 8.42276 19.002 6.90595C17.2429 5.39462 14.8525 4 12 4ZM10 12C10 10.8954 10.8955 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8955 14 10 13.1046 10 12ZM12 8C9.7909 8 8.00004 9.79086 8.00004 12C8.00004 14.2091 9.7909 16 12 16C14.2092 16 16 14.2091 16 12C16 9.79086 14.2092 8 12 8Z"
          fill="currentColor"
        ></path>
      </g></svg
    >
  </button>
</div>

<div
  class={`absolute bottom-0 pointer-events-none w-full ${guiVars.hidden ? "hidden" : ""}`}
>
  <div class="pointer-events-none flex flex-row items-center justify-center">
    <button
      class="btn btn-lg btn-circle pointer-events-auto"
      on:click={onUserInfo}
    >
      {#if loggedInUser && paidUser}
        <svg
          class={`${paidUser ? "" : "hidden"}`}
          viewBox="-5.5 -5 34 34"
          width="50"
          height="50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g><g id="SVGRepo_iconCarrier">
            <path
              d="M4 21C4 17.4735 6.60771 14.5561 10 14.0709M16.4976 16.2119C15.7978 15.4328 14.6309 15.2232 13.7541 15.9367C12.8774 16.6501 12.7539 17.843 13.4425 18.6868C13.8312 19.1632 14.7548 19.9983 15.4854 20.6353C15.8319 20.9374 16.0051 21.0885 16.2147 21.1503C16.3934 21.203 16.6018 21.203 16.7805 21.1503C16.9901 21.0885 17.1633 20.9374 17.5098 20.6353C18.2404 19.9983 19.164 19.1632 19.5527 18.6868C20.2413 17.843 20.1329 16.6426 19.2411 15.9367C18.3492 15.2307 17.1974 15.4328 16.4976 16.2119ZM15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g></svg
        >
      {:else if loggedInUser}
        <svg
          class={`${loggedInUser && paidUser ? "hidden" : ""}`}
          viewBox="-5.5 -5 34 34"
          width="50"
          height="50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g><g id="SVGRepo_iconCarrier">
            <path
              d="M14 19.2857L15.8 21L20 17M4 21C4 17.134 7.13401 14 11 14C12.4872 14 13.8662 14.4638 15 15.2547M15 7C15 9.20914 13.2091 11 11 11C8.79086 11 7 9.20914 7 7C7 4.79086 8.79086 3 11 3C13.2091 3 15 4.79086 15 7Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g></svg
        >
      {:else}
        <svg
          class={`${loggedInUser ? "hidden" : ""} opacity-50`}
          viewBox="-5.5 -5 34 34"
          width="50"
          height="50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g><g id="SVGRepo_iconCarrier">
            <path
              d="M3 21C3 21.5523 3.44772 22 4 22C4.55228 22 5 21.5523 5 21H3ZM11.8584 15.0608C12.4051 15.139 12.9117 14.7592 12.9899 14.2125C13.0681 13.6658 12.6883 13.1592 12.1416 13.081L11.8584 15.0608ZM17.29 17.2929C16.8994 17.6834 16.8994 18.3166 17.29 18.7071C17.6805 19.0976 18.3137 19.0976 18.7042 18.7071L17.29 17.2929ZM15.0916 14.7507C14.954 15.2856 15.2759 15.8308 15.8108 15.9684C16.3457 16.1061 16.8908 15.7841 17.0285 15.2493L15.0916 14.7507ZM17.9971 20C17.4448 20 16.9971 20.4477 16.9971 21C16.9971 21.5523 17.4448 22 17.9971 22V20ZM18.0071 22C18.5594 22 19.0071 21.5523 19.0071 21C19.0071 20.4477 18.5594 20 18.0071 20V22ZM14 7C14 8.65685 12.6569 10 11 10V12C13.7614 12 16 9.76142 16 7H14ZM11 10C9.34315 10 8 8.65685 8 7H6C6 9.76142 8.23858 12 11 12V10ZM8 7C8 5.34315 9.34315 4 11 4V2C8.23858 2 6 4.23858 6 7H8ZM11 4C12.6569 4 14 5.34315 14 7H16C16 4.23858 13.7614 2 11 2V4ZM5 21C5 17.6863 7.68629 15 11 15V13C6.58172 13 3 16.5817 3 21H5ZM11 15C11.292 15 11.5786 15.0208 11.8584 15.0608L12.1416 13.081C11.7682 13.0276 11.387 13 11 13V15ZM18.997 15.5C18.997 15.6732 18.9516 15.8053 18.6776 16.0697C18.5239 16.218 18.3429 16.3653 18.0919 16.574C17.8536 16.7723 17.5741 17.0087 17.29 17.2929L18.7042 18.7071C18.92 18.4913 19.1405 18.3033 19.3709 18.1116C19.5887 17.9305 19.8452 17.7223 20.0665 17.5087C20.5426 17.0493 20.997 16.4314 20.997 15.5H18.997ZM17.997 14.5C18.5493 14.5 18.997 14.9477 18.997 15.5H20.997C20.997 13.8431 19.6539 12.5 17.997 12.5V14.5ZM17.0285 15.2493C17.1396 14.8177 17.5325 14.5 17.997 14.5V12.5C16.5978 12.5 15.4246 13.457 15.0916 14.7507L17.0285 15.2493ZM17.9971 22H18.0071V20H17.9971V22Z"
              fill="currentColor"
            ></path>
          </g></svg
        >
      {/if}
    </button>
    <div class="w-1/3 max-w-52 bg-base-200 py-2 px-4 rounded-full">
      <input
        type="range"
        min="1"
        max="10"
        value="5"
        on:input={(e) => {
          guiVars.speed = 11 - parseInt(e.target.value);
        }}
        class="range pointer-events-auto"
      />
      <p>speed</p>
    </div>
    <div class="w-1/3 max-w-52 bg-base-200 py-2 px-4 rounded-full">
      <input
        type="range"
        min="2"
        max="30"
        value="15"
        on:input={(e) => {
          guiVars.pulseTime = Math.pow(parseInt(e.target.value) / 10, 5);
        }}
        class="range pointer-events-auto"
      />
      <p>pulse</p>
    </div>
    <button
      class="btn btn-lg btn-circle pointer-events-auto"
      on:click={() => {
        guiVars.screenShotNow = true;
      }}
      ><svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="currentColor"
        width="40"
        height="40"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M3 16.8V9.2C3 8.0799 3 7.51984 3.21799 7.09202C3.40973 6.71569 3.71569 6.40973 4.09202 6.21799C4.51984 6 5.0799 6 6.2 6H7.25464C7.37758 6 7.43905 6 7.49576 5.9935C7.79166 5.95961 8.05705 5.79559 8.21969 5.54609C8.25086 5.49827 8.27836 5.44328 8.33333 5.33333C8.44329 5.11342 8.49827 5.00346 8.56062 4.90782C8.8859 4.40882 9.41668 4.08078 10.0085 4.01299C10.1219 4 10.2448 4 10.4907 4H13.5093C13.7552 4 13.8781 4 13.9915 4.01299C14.5833 4.08078 15.1141 4.40882 15.4394 4.90782C15.5017 5.00345 15.5567 5.11345 15.6667 5.33333C15.7216 5.44329 15.7491 5.49827 15.7803 5.54609C15.943 5.79559 16.2083 5.95961 16.5042 5.9935C16.561 6 16.6224 6 16.7454 6H17.8C18.9201 6 19.4802 6 19.908 6.21799C20.2843 6.40973 20.5903 6.71569 20.782 7.09202C21 7.51984 21 8.0799 21 9.2V16.8C21 17.9201 21 18.4802 20.782 18.908C20.5903 19.2843 20.2843 19.5903 19.908 19.782C19.4802 20 18.9201 20 17.8 20H6.2C5.0799 20 4.51984 20 4.09202 19.782C3.71569 19.5903 3.40973 19.2843 3.21799 18.908C3 18.4802 3 17.9201 3 16.8Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      ></button
    >
  </div>

  <div class="py-3 w-full flex items-center justify-center">
    <!-- Diagonals -->
    <label class="btn btn-circle swap swap-rotate pointer-events-auto">
      <input type="checkbox" class="hidden" bind:checked={guiVars.diagonals} />
      <svg
        class="swap-on fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="-8.5 0 32 32"
        version="1.1"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <title>arrows-alt</title>
          <path
            d="M13.76 18.48v0c-0.48 0-0.84 0.36-0.84 0.84v1.12l-4.4-4.4 4.4-4.4v1.12c0 0.48 0.36 0.84 0.84 0.84s0.84-0.36 0.84-0.84v-3.16c0-0.48-0.36-0.84-0.88-0.84v0h-3.16c-0.48 0-0.84 0.36-0.84 0.84s0.36 0.84 0.84 0.84h1.12l-4.4 4.4-4.4-4.4h1.16c0.48 0 0.84-0.36 0.84-0.84s-0.36-0.84-0.84-0.84h-3.16c-0.52 0-0.88 0.4-0.88 0.88v3.16c0 0.48 0.36 0.8 0.84 0.8v0c0.48 0 0.84-0.36 0.84-0.84v-1.12l4.4 4.4-4.4 4.4v-1.12c0-0.48-0.36-0.84-0.84-0.84-0.44-0.040-0.8 0.32-0.8 0.8v3.16c0 0.44 0.24 0.8 0.84 0.8h3.16c0.48 0 0.84-0.36 0.84-0.84s-0.36-0.8-0.84-0.8h-1.12l4.4-4.4 4.4 4.4h-1.12c-0.48 0-0.84 0.36-0.84 0.84s0.36 0.84 0.84 0.84h3.16c0.56 0 0.88-0.32 0.88-0.8v0-3.16c-0.040-0.48-0.44-0.84-0.88-0.84z"
          ></path>
        </g></svg
      >
      <svg
        class="swap-off fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="-6 0 32 32"
        version="1.1"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <title>arrows</title>
          <path
            d="M19.72 15.4l-2.24-2.2c-0.32-0.32-0.84-0.32-1.2 0-0.32 0.32-0.32 0.84 0 1.2l0.8 0.8h-6.28v-6.24l0.8 0.8c0.16 0.16 0.36 0.24 0.6 0.24 0.2 0 0.44-0.080 0.6-0.24 0.32-0.32 0.32-0.84 0-1.2l-2.24-2.24c-0.6-0.72-1.2-0.040-1.2-0.040l-2.24 2.24c-0.32 0.32-0.32 0.84 0 1.2 0.32 0.32 0.84 0.32 1.2 0l0.8-0.8v6.28h-6.2l0.8-0.8c0.32-0.32 0.32-0.84 0-1.2-0.32-0.32-0.84-0.32-1.2 0l-2.24 2.24c-0.32 0.32-0.32 0.84 0 1.16l2.24 2.2c0.16 0.16 0.36 0.24 0.6 0.24 0.2 0 0.44-0.080 0.6-0.24 0.32-0.32 0.32-0.84 0-1.2l-0.8-0.8h6.24v6.24l-0.8-0.8c-0.32-0.32-0.84-0.32-1.2 0-0.32 0.32-0.32 0.84 0 1.2l2.24 2.24c0.4 0.4 0.68 0.4 1.12-0.040l2.24-2.24c0.44-0.28 0.44-0.8 0.080-1.12-0.32-0.32-0.84-0.32-1.2 0l-0.8 0.8v-6.28h6.24l-0.8 0.8c-0.32 0.32-0.32 0.84 0 1.2 0.16 0.16 0.36 0.24 0.6 0.24s0.44-0.080 0.6-0.24l2.24-2.24c0.32-0.28 0.32-0.88 0-1.16z"
          ></path>
        </g></svg
      >
    </label>

    <!-- Pause -->
    <label class="btn btn-circle swap swap-rotate pointer-events-auto">
      <input type="checkbox" class="hidden" bind:checked={guiVars.pause} />
      <svg
        class="swap-off fill-current"
        width="20"
        height="20"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <g>
            <path
              d="m 3 1 h 3 c 0.550781 0 1 0.449219 1 1 v 12 c 0 0.550781 -0.449219 1 -1 1 h -3 c -0.550781 0 -1 -0.449219 -1 -1 v -12 c 0 -0.550781 0.449219 -1 1 -1 z m 0 0"
            ></path>
            <path
              d="m 10 1 h 3 c 0.550781 0 1 0.449219 1 1 v 12 c 0 0.550781 -0.449219 1 -1 1 h -3 c -0.550781 0 -1 -0.449219 -1 -1 v -12 c 0 -0.550781 0.449219 -1 1 -1 z m 0 0"
            ></path>
          </g>
        </g></svg
      >

      <svg
        class="swap-on fill-current"
        width="20"
        height="20"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="m 2 2.5 v 11 c 0 1.5 1.269531 1.492188 1.269531 1.492188 h 0.128907 c 0.246093 0.003906 0.488281 -0.050782 0.699218 -0.171876 l 9.796875 -5.597656 c 0.433594 -0.242187 0.65625 -0.734375 0.65625 -1.226562 c 0 -0.492188 -0.222656 -0.984375 -0.65625 -1.222656 l -9.796875 -5.597657 c -0.210937 -0.121093 -0.453125 -0.175781 -0.699218 -0.175781 h -0.128907 s -1.269531 0 -1.269531 1.5 z m 0 0"
          ></path>
        </g></svg
      >
    </label>

    <!-- Up -->
    <label class="btn btn-circle swap pointer-events-auto">
      <input type="checkbox" class="hidden" bind:checked={guiVars.up} />
      <svg
        class={`swap-on fill-current ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M12 6V18M12 6L7 11M12 6L17 11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >

      <svg
        class={`swap-off fill-current opacity-50 ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M12 6V18M12 6L7 11M12 6L17 11"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >
    </label>

    <!-- Down -->
    <label class="btn btn-circle swap pointer-events-auto">
      <input type="checkbox" class="hidden" bind:checked={guiVars.down} />
      <svg
        class={`swap-on fill-current ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M12 6V18M12 18L7 13M12 18L17 13"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >
      <svg
        class={`swap-off fill-current opacity-50 ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M12 6V18M12 18L7 13M12 18L17 13"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >
    </label>

    <!-- Left -->
    <label class="btn btn-circle swap pointer-events-auto">
      <input type="checkbox" class="hidden" bind:checked={guiVars.right} />
      <svg
        class={`swap-on fill-current ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M6 12H18M6 12L11 7M6 12L11 17"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >

      <svg
        class={`swap-off fill-current opacity-50 ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M6 12H18M6 12L11 7M6 12L11 17"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >
    </label>

    <!-- Right-->
    <label class="btn btn-circle swap pointer-events-auto">
      <input type="checkbox" class="hidden" bind:checked={guiVars.left} />
      <svg
        class={`swap-on fill-current ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M6 12H18M18 12L13 7M18 12L13 17"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >

      <svg
        class={`swap-off fill-current opacity-50 ${guiVars.diagonals ? "rotate-45" : ""}`}
        width="30"
        height="30"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g><g id="SVGRepo_iconCarrier">
          <path
            d="M6 12H18M18 12L13 7M18 12L13 17"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </g></svg
      >
    </label>

    <button
      class={`btn btn-circle ${paidUser ? "" : "text-opacity-50"} pointer-events-auto`}
      on:click={onChangeColor}>Color</button
    >
    <button
      class="btn btn-circle pointer-events-auto"
      on:click={() => {
        guiVars.Reset();
      }}>Reset</button
    >
  </div>
</div>

<canvas class="absolute top-0 left-0 -z-10" id="gl"></canvas>

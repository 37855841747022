<script>
  import { useForm } from "@inertiajs/svelte";

  export let errors;

  let form = useForm({
    email: null,
    password: null,
  });

  function submit() {
    $form.post("/session");
  }
</script>

{#if errors}
  <div role="alert" class="alert">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      class="h-6 w-6 shrink-0 stroke-current"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
    <span>{errors}</span>
  </div>
{/if}

<section class="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
  <h2 class="text-3xl mt-8 text-center">Sign In</h2>
  <p class="my-5 text-center">Login to your account.</p>
  <form
    on:submit|preventDefault={submit}
    class="flex flex-col items-center gap-3"
  >
    <input
      class="input input-bordered w-full max-w-xs"
      placeholder="email@example.com"
      type="text"
      bind:value={$form.email}
    />
    <input
      class="input input-bordered w-full max-w-xs"
      placeholder="password"
      type="password"
      bind:value={$form.password}
    />

    <button class="btn btn-accent" type="submit" disabled={$form.processing}
      >Sign In</button
    >
    <p class="text-sm">Or</p>
    <a class="btn btn-outline" href="/users/new">Create a New Account</a>
  </form>
</section>

<script>
  import { router } from '@inertiajs/svelte'
  export let user;

  let formValues = {
    name: user.name,
    email: user.email,
    subscription_status: user.subscription_status
  }

  function submit() {
    router.patch(`/users/${user.id}`, formValues, {
      onSuccess: (page) => {
        window.location.href = "/users"
      }
    })
  }
</script>

<section class="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
  <h2 class="text-3xl my-8 text-center">Edit User - #{user.id}</h2>
  <form on:submit|preventDefault={submit} class="flex flex-col items-center gap-3">
    <input id="name" class="input input-bordered w-full max-w-xs" placeholder="Your Name" type="text" bind:value={formValues.name} />
    <input id="email" class="input input-bordered w-full max-w-xs" placeholder="email@example.com" type="text" bind:value={formValues.email} />
    <select bind:value={formValues.subscription_status} class="select select-bordered w-full max-w-xs">
      <option>free</option>
      <option>paid</option>
      <option>homie</option>
    </select>
   
    <button class="btn btn-accent" type="submit">Update user {user.id}</button>
  </form>

  <a href="/users" class="mx-auto btn btn-ghost">Back to all users</a>
</section>
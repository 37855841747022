<script>
import { router } from '@inertiajs/svelte'

export let errors;

let formValues = {
  name: null,
  email: null,
  password: null,
}

function submit() {
  router.post('/users', formValues, {
    onSuccess: (page) => {
      window.location.href = "/checkout"
    }
  })
}
</script>

{#if errors}
  <div role="alert" class="alert">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      class="h-6 w-6 shrink-0 stroke-current">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
    <span>{errors}</span>
  </div>
{/if}

<section class="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
  <h2 class="text-3xl mt-8 text-center">Sign Up</h2>
  <p class="my-5 text-center">Create your Assimilation account.</p>
  <form on:submit|preventDefault={submit} class="flex flex-col items-center gap-3">
    <input id="name" class="input input-bordered w-full max-w-xs" placeholder="Your Name" type="text" bind:value={formValues.name} />
    <input id="email" class="input input-bordered w-full max-w-xs" placeholder="email@example.com" type="text" bind:value={formValues.email} />
    <input id="password" class="input input-bordered w-full max-w-xs" placeholder="password" type="password" bind:value={formValues.password} />
   
    <button class="btn btn-accent" type="submit">Sign Up</button>
  </form>
</section>

<script>
import { useForm } from '@inertiajs/svelte'

export let errors

let form = useForm({
  email_address: null,
})

function submit() {
  $form.post('/passwords')
}
</script>

{#if errors}
  <div role="alert" class="alert">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      class="h-6 w-6 shrink-0 stroke-current">
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
    <span>{errors}</span>
  </div>
{/if}

<section class="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
  <h1 class="text-3xl my-8 text-center">Forgot your password?</h1>

  <form on:submit|preventDefault={submit} class="flex flex-col items-center gap-3">
    <input
      class="input input-bordered w-full max-w-xs"
      type="email"
      bind:value={$form.email_address}
      required
      autocomplete="username"
      placeholder="Enter your email address"
    />
    <button class="btn btn-accent mt-3" type="submit" disabled={$form.processing}>
      Email reset instructions
    </button>
  </form>
</section>

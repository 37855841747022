<section class="bg-base-200 py-16 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <div class="mx-auto max-w-xl">
    <article class="prose-lg">
      <h1>The Prophecy of Assimilation</h1>
      <p>We start as the pixels do -- a mere seed.</p>
      <p>Seeds whose energy
      <p>sprouts, spreads, wanes,</p>
      <p>and diminishes.</p>
      <br>
      <p>We are both mathematical and mysterious.</p>
      <p>We have endings and beginnings.</p>
      <p>Although, it's hard to tell where we end and we begin.</p>
      <br>
      <p>We and the pixels are individual.</p>
      <p>And yet, we Assimilate -- into One.</p>
    </article>

    <a class="mt-16 btn btn-ghost" href="/">← Back Home</a>
  </div>


</section>

<footer class="footer footer-center bg-base-300 text-base-content p-4">
  <aside>
    <p>Copyright © {new Date().getFullYear()} - All right reserved by <a class="underline" href="https://lokbros.com">LokBros Studio</a>.</p>
  </aside>
</footer>